import isVisible from '../element/is_visible';

function getFocusable(context = document, autofocus = false) {
  let focusable = [
    ...context.querySelectorAll(
      // eslint-disable-next-line max-len
      'button, [href], select, textarea, input:not([type="hidden"]), [tabindex]:not([tabindex="-1"]), [role="button"]',
    ),
  ];

  focusable = focusable.filter((e) => isVisible(e) && (!autofocus || e.dataset.disableAutofocus !== 'true'));

  return focusable;
}

export default getFocusable;
