import addListener from './add_listener';
import getFocusable from './get_focusable';
import isVariableDefinedNotNull from './is_variable_defined_not_null';
import removeListener from './remove_listener';

class FocusTrap {
  constructor() {
    if (!FocusTrap.instance) {
      this._data = [];
      this._props = { listenerId: null };

      FocusTrap.instance = this;
      Object.freeze(FocusTrap.instance);
    }

    // eslint-disable-next-line no-constructor-return
    return FocusTrap.instance;
  }

  _trapTabKey(event) {
    if (event.keyCode !== 9) {
      return;
    }

    const openedDialog = document.querySelector('dialog[open]');

    if (openedDialog) {
      return;
    }

    const focusableItems = getFocusable(this.context);
    const focusedItem = document.activeElement;

    const focusedItemIndex = focusableItems.indexOf(focusedItem);

    if (focusableItems.length === 0) {
      event.preventDefault();
      return;
    }

    if (focusedItemIndex === -1) {
      focusableItems[0].focus();
      event.preventDefault();
      return;
    }

    if (event.shiftKey) {
      if (focusedItemIndex === 0) {
        focusableItems[focusableItems.length - 1].focus();
        event.preventDefault();
      }

      return;
    }

    if (focusedItemIndex === focusableItems.length - 1) {
      focusableItems[0].focus();
      event.preventDefault();
    }
  }

  _focusFirstFocusable() {
    const focusableItems = getFocusable(this.context, true);

    if (focusableItems.length === 0) {
      if (isVariableDefinedNotNull(document.activeElement)) {
        document.activeElement.blur();
      }

      return;
    }

    focusableItems[0].focus();
  }

  _focusPreviouslyActiveElement() {
    if (!this.previouslyActiveElement) {
      return;
    }

    this.previouslyActiveElement.focus();
  }

  trap(context, options) {
    this._data.push({
      context,
      options,
      previouslyActiveElement: null,
    });

    if (!this.options.omitPreviousActive) {
      this.previouslyActiveElement = document.activeElement;
    }

    if (!this.listenerId) {
      this.listenerId = addListener(window, 'keydown', this._trapTabKey.bind(this));
    }

    this._focusFirstFocusable();
  }

  release(context = this.context) {
    this._focusPreviouslyActiveElement();

    const contextIndex = this._data.findIndex((data) => data.context === context);

    if (contextIndex < 0) {
      return;
    }

    this._data.splice(contextIndex, 1);

    if (this._data.length === 0 && this.listenerId) {
      removeListener(window, { id: this.listenerId });
      this.listenerId = null;
    }
  }

  get context() {
    if (!this._data[this._currentDataIndex]) {
      return null;
    }

    return this._data[this._currentDataIndex].context;
  }

  get options() {
    if (!this._data[this._currentDataIndex]) {
      return null;
    }

    return this._data[this._currentDataIndex].options;
  }

  get _currentDataIndex() {
    return this._data.length - 1;
  }

  get listenerId() {
    return this._props.listenerId;
  }

  set listenerId(value) {
    this._props.listenerId = value;
  }

  get previouslyActiveElement() {
    if (!this._data[this._currentDataIndex]) {
      return null;
    }

    return this._data[this._currentDataIndex].previouslyActiveElement;
  }

  set previouslyActiveElement(value) {
    this._data[this._currentDataIndex].previouslyActiveElement = value;
  }
}

function trapFocus(context, { omitPreviousActive = false } = {}) {
  const focusTrap = new FocusTrap();
  focusTrap.trap(context, { omitPreviousActive });
}

function releaseFocus(context) {
  const focusTrap = new FocusTrap();
  focusTrap.release(context);
}

export { trapFocus, releaseFocus };
