import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function setBodyOverflowHidden(counterParam = 'overflowHiddenCnt') {
  if (!isVariableDefinedNotNull(document.body[counterParam])) {
    document.body[counterParam] = 0;
  }

  document.body[counterParam] += 1;

  if (document.body[counterParam] > 0) {
    document.documentElement.classList.add('isLocked');
  }
}

export default setBodyOverflowHidden;
