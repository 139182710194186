import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';

function removeBodyOverflowHidden(counterParam = 'overflowHiddenCnt', setCounterTo = null) {
  if (!isVariableDefinedNotNull(document.body[counterParam])) {
    return;
  }

  document.body[counterParam] -= 1;

  if (document.body[counterParam] < 0) {
    document.body[counterParam] = 0;
  }

  if (document.body[counterParam] > 0 && isVariableDefinedNotNull(setCounterTo)) {
    document.body[counterParam] = setCounterTo;
  }

  if (document.body[counterParam] === 0) {
    document.documentElement.classList.remove('isLocked');
  }
}

export default removeBodyOverflowHidden;
